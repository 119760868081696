/* Global Styles */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800&display=swap');

:root {
  --primary-color: #0043ff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Nunito", sans-serif;
  --webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  width: 100%;
  height: 100vh;
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4 {
  color: #fff;
}

a {
  color: #fff;
  text-decoration: none;
}

p {
  margin: 0.5rem 0;
}

img {
  width: 100%;
}

.showcase {
  height: 100vh;
  display: flex;
  overflow: 'hidden';
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-container video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;

}

.video-container:after {
  content: "";
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(7, 1, 97, 0.8);
  position: absolute;
}

.showcase-top {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  height: 90px;
}

.showcase-top img {
  width: 50px;
  position: absolute;
  top: 4rem;
  left: 5rem;
  transform: translate(-50%, -50%);
  margin-left: 0;
  opacity: 1;
}

.showcase-content {
  position: relative;
  z-index: 2;
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 13%;
}

.showcase-content h1 {
  font-weight: 700;
  font-size: 4rem;
  line-height: 1.4;
  letter-spacing: 0.03em;
  margin: 0 0 .5rem;
  opacity: 0;
  animation: HeroAnimation;
  animation-duration: 2s;
  animation-delay: 0.15s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
}

.showcase-content h2 {
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: 0.03em;
  margin: 0 0 2rem;
  animation: HeroAnimation 3s 0.3s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
  opacity: 0;
}

/* Btn */

.btnOptions {
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btnOptions button {
  opacity: 0.4;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  display: inline-block;
  padding: 16px 40px;
  font-size: 1rem;
  border-radius: 8px;
  text-align: center;
  border: 2px solid #0043ff;
  margin: 0 4px;
  transition: cubic-bezier(0.2, 0.8, 0.2, 1);
}

.btnOptions button:hover {
  color: fff;
  background: #002fb1;
  opacity: 0.6;
}

.btnOptions .btn-active {
  color: #fff;
  background: #0043ff;
  opacity: 1;
}

.btnOptions .btn-active:hover {
  background: #0043ff;
  opacity: 1;
}


.btn-login {
  position: fixed;
  top: 3rem;
  right: 3rem;
  background-color: rgba(0, 0, 0, 0);
  color: #FFF;
  border: solid 2px rgba(255, 255, 255, 0.5);
  padding: 12px 32px;
  border-radius: 8px;
  cursor: pointer;
}


.btn-login:hover {
  border: solid 2px rgba(255, 255, 255, 0.7);
}
/* mouse */
/* 
.scroll-downs {
  position: fixed;
  bottom: 3rem;
  margin: auto;
  width: 34px;
  height: 55px;
  z-index: 2;
}
.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
} */

/* Progress Bar */

/* Keyframes Hero Animations */

@keyframes HeroAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}


/* @media */

@media(max-width: 1024px) {
  .showcase {
    height: 100%;
  }

  .body {
    height: 100%;
  }

  .showcase-content {
    position: relative;
    z-index: 2;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-top: 10%;
  }

  .btnOptions {
    position: fixed;
    bottom: 17%;
    display: flex;
    flex-direction: row;
  }

  .btnOptions button {
    margin: 0 10px;
  }

  .btn-login {
    right: 2rem;
    top: 2rem;
  }

  .showcase-top img {
    top: 4rem;
    left: 4rem;
  }

  .showcase-content h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    width: 80%;
  }

  .showcase-content h2 {
  font-size: 1.4rem;
  line-height: 1.5;
  width: 75%;
  }

  .appStores {
    position: fixed;
    bottom: 1rem;
    /*! right: 0; */
    display: flex;
    /*! flex-direction: row; */
    /*! justify-content: space-between; */
    /*! align-items: center; */
    width: 100%;
    height: 50px;
    margin: 0;
    justify-content: center;
  }

  .appStores div {
    margin: 0 10px;
  }

}

@media(max-width: 700px) {

  .showcase-top img {
    top: 3rem;
    left: 3rem;
    width: 40px;
  }

  .showcase-content h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    width: 90%;
  }
  
  .showcase-content h2 {
    font-size: 1.3rem;
    line-height: 1.5;
    }


  .showcase-content {
    position: relative;
    z-index: 2;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-top: 25%;
  }

  .btnOptions {
    position: fixed;
    bottom: 17%;
    display: flex;
    flex-direction: row;
  }

  .btnOptions button {
    margin: 0 10px;
    padding: 12px 30px;
  }

  .appStores {
    position: fixed;
    bottom: 1rem;
    /*! right: 0; */
    display: flex;
    /*! flex-direction: row; */
    /*! justify-content: space-between; */
    /*! align-items: center; */
    width: 100%;
    height: 50px;
    margin: 0;
    justify-content: center;
  }
  
  .apple {
    width: 121px;

  }

  .google {
    width: 129px;
  }

  .appStores div {
    margin: 0 10px;
  }
}

@media(max-width: 375px) {

  .showcase-top img {
    top: 3rem;
    left: 3rem;
    width: 40px;
  }

  .btn-login {
    display: none;
  }

  .showcase-content h1 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .showcase-content h2 {
    font-size: 1.02rem;
    line-height: 1.5;
    width: 100%;
    }


  .showcase-content {
    position: relative;
    z-index: 2;
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-top: 40%;
  }

  .btnOptions {
    bottom: 20%;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .btnOptions button {
    margin: 0 5px;
  }

  .appStores {
    bottom: 2%;
    /*! right: 0; */
    display: flex;
    justify-content: center;
    /*! flex-direction: row; */
    /*! justify-content: space-between; */
    /*! align-items: center; */
    width: 100%;
    height: 50px;
  }

  .appStores div {
    margin: 0 5px;
  }
}